import React from 'react';
import { ChatContext } from '../context/ChatContext';

const Header = () => {
  const {closeChat} = React.useContext(ChatContext);
  return (
    <div className='header-chat'>
      <span className="title-chat">
        Automatic Chat
      </span>
      <span className="close-chat-button" onClick={()=> closeChat()}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0ZM12 10.5446L9.21257 7.75714C9.01957 7.56414 8.7578 7.45571 8.48486 7.45571C8.21191 7.45571 7.95014 7.56414 7.75714 7.75714C7.56414 7.95014 7.45571 8.21191 7.45571 8.48486C7.45571 8.7578 7.56414 9.01957 7.75714 9.21257L10.5446 12L7.75714 14.7874C7.66158 14.883 7.58577 14.9964 7.53405 15.1213C7.48233 15.2462 7.45571 15.38 7.45571 15.5151C7.45571 15.6503 7.48233 15.7841 7.53405 15.909C7.58577 16.0338 7.66158 16.1473 7.75714 16.2429C7.85271 16.3384 7.96616 16.4142 8.09102 16.4659C8.21588 16.5177 8.34971 16.5443 8.48486 16.5443C8.62001 16.5443 8.75383 16.5177 8.87869 16.4659C9.00356 16.4142 9.11701 16.3384 9.21257 16.2429L12 13.4554L14.7874 16.2429C14.883 16.3384 14.9964 16.4142 15.1213 16.4659C15.2462 16.5177 15.38 16.5443 15.5151 16.5443C15.6503 16.5443 15.7841 16.5177 15.909 16.4659C16.0338 16.4142 16.1473 16.3384 16.2429 16.2429C16.3384 16.1473 16.4142 16.0338 16.4659 15.909C16.5177 15.7841 16.5443 15.6503 16.5443 15.5151C16.5443 15.38 16.5177 15.2462 16.4659 15.1213C16.4142 14.9964 16.3384 14.883 16.2429 14.7874L13.4554 12L16.2429 9.21257C16.3384 9.11701 16.4142 9.00356 16.4659 8.87869C16.5177 8.75383 16.5443 8.62001 16.5443 8.48486C16.5443 8.34971 16.5177 8.21588 16.4659 8.09102C16.4142 7.96616 16.3384 7.85271 16.2429 7.75714C16.1473 7.66158 16.0338 7.58577 15.909 7.53405C15.7841 7.48233 15.6503 7.45571 15.5151 7.45571C15.38 7.45571 15.2462 7.48233 15.1213 7.53405C14.9964 7.58577 14.883 7.66158 14.7874 7.75714L12 10.5446Z" fill="#13239B"/>
        </svg>
      </span>

    </div>
  );
}

export default Header;